import "./AboutUs.scss";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useAnimation from "../../../hooks/useAnimation";
import useWindowWidth from "../../../hooks/useWindowWidth";
import getImageUrl from "../../../Utils/Services/img/imgFetch";
const AboutUs = () => {
  const { animateFadeInWithMove, animateWithScroll, animateVerticalMovement } =
    useAnimation();
  const { isMobile } = useWindowWidth();
  const { t } = useTranslation();

  const aboutUsTextTransleted = t("about.text");
  const aboutUsTitleTranslated = t("about.title");

  const initAnimation = () => {
    const aboutUsText = document.getElementById("aboutUsText");

    const aboutUsPicture = document.getElementById("aboutUsPicture");

    const aboutUsWave = document.getElementById("aboutUsWave");
    const aboutUsTitle = document.getElementById("aboutUsTitle");
    if (aboutUsTitle) animateFadeInWithMove(aboutUsTitle, 0.6, aboutUsTitle);

    if (aboutUsText) animateFadeInWithMove(aboutUsText, 0.6, aboutUsText);

    if (aboutUsWave) {
      animateWithScroll({
        element: aboutUsWave,
        startX: -20,
        startY: 0,
        endX: isMobile ? 10 : 100,
        endY: 0,
        scrub: 1,
        startTrigger: "top bottom",
      });
    }

    if (!aboutUsPicture) {
      return;
    }
    animateVerticalMovement(
      "#aboutUsPicture",
      isMobile ? "0rem" : "-5rem",
      isMobile ? "5rem" : "13rem",
    );
  };

  useEffect(() => {
    const timeoutId = setTimeout(initAnimation, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <section id="about-section" className="aboutUs">
      <h1 id="aboutUsTitle">
        <span>{aboutUsTitleTranslated}</span>
      </h1>
      <div id="aboutUsText">
        <p>{aboutUsTextTransleted}</p>
      </div>
      <figure>
        <img
          src={getImageUrl("aboutUs.png")}
          alt="aboutUs"
          id="aboutUsPicture"
        />
      </figure>
      <img src={getImageUrl("wave.png")} alt="aboutUs" id="aboutUsWave" />
    </section>
  );
};

export default AboutUs;
