import "./Products.scss";

import gsap from "gsap";
import React, { useEffect, useRef } from "react";

import ContactForm from "../../components/ContactForm/ContactForm";
import ProductsPage from "./ProductsPage/ProductsPage";
import ServicesPage from "./ServicesPage/ServicesPage";
import SystemsPage from "./SystemsPage/SystemsPage";

interface ProductsProps {
  page: string;
}

const Products: React.FC<ProductsProps> = ({ page }) => {
  const productsRef = useRef(null);
  const servicesRef = useRef(null);
  const systemsRef = useRef(null);
  const titleRef = useRef(null);

  const fadeIn = (element: HTMLElement | null) => {
    gsap.to(element, { opacity: 1, y: 0, duration: 0.5 });
  };

  const fadeOut = (element: HTMLElement | null) => {
    gsap.to(element, { opacity: 0, y: 0, duration: 0.5 });
  };

  const titleFadeIn = (element: HTMLElement | null) => {
    gsap.to(element, { opacity: 1, duration: 0.5 });
  };
  const titleFadeOut = (element: HTMLElement | null) => {
    gsap.to(element, { opacity: 0, duration: 0.5 });
  };

  useEffect(() => {
    // Récupérer l'élément actuel en fonction de displayType
    const currentElement =
      page === "Products"
        ? productsRef.current
        : page === "Services"
        ? servicesRef.current
        : systemsRef.current;

    // Appliquer l'animation
    fadeIn(currentElement);
    titleFadeIn(titleRef.current);

    // Animation de sortie lors du changement de composant
    return () => {
      fadeOut(currentElement);
      titleFadeOut(titleRef.current);
    };
  }, [page]);

  return (
    <div className="productsDiv">
      <div className="header">
        <h1 ref={titleRef}>{page}</h1>
      </div>
      <div
        ref={productsRef}
        style={{ opacity: 0 }}
        className="displayTypeWrapper"
      >
        {page === "Products" && <ProductsPage />}
      </div>
      <div
        ref={servicesRef}
        style={{ opacity: 0 }}
        className="displayTypeWrapper"
      >
        {page === "Services" && <ServicesPage />}
      </div>
      <div
        ref={systemsRef}
        style={{ opacity: 0 }}
        className="displayTypeWrapper"
      >
        {page === "Systems" && <SystemsPage />}
      </div>
      <ContactForm />
    </div>
  );
};

export default Products;
