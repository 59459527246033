import "./ServicesSection.scss";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../components/Utils/Button/Button";
import useAnimatedNavigate from "../../../hooks/useAnimatedNavigate";
import useAnimation from "../../../hooks/useAnimation";
import getImageUrl from "../../../Utils/Services/img/imgFetch";

const ServicesSection = () => {
  const { animateFadeInWithMove, animateVerticalMovement } = useAnimation();
  const { t } = useTranslation();

  const animatedNavigate = useAnimatedNavigate();

  const initAnimation = () => {
    const servicesSectionTitle = document.getElementById(
      "servicesSectionTitle",
    );
    const servicesSectionText = document.getElementById("servicesSectionText");
    if (servicesSectionTitle)
      animateFadeInWithMove(servicesSectionTitle, 0.6, servicesSectionTitle);
    if (servicesSectionText)
      animateFadeInWithMove(servicesSectionText, 0.6, servicesSectionText);
    const servicesSectionDiv = document.querySelector(
      ".servicesSection .imageWrapper",
    );
    if (servicesSectionDiv instanceof HTMLElement) {
      animateFadeInWithMove(servicesSectionDiv, 0.6, servicesSectionDiv);
    }

    animateVerticalMovement("#servicesSectionPicture", "0rem", "5rem");
  };

  useEffect(() => {
    const timeoutId = setTimeout(initAnimation, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className="servicesSection" id="hire-section">
      <h1 id="servicesSectionTitle">{t("servicesSection.title")}</h1>
      <div className="imageWrapper">
        <img
          src={getImageUrl("servicesSection.jpg")}
          alt="servicesSection"
          id="servicesSectionPicture"
        />
      </div>
      <div className="contentWrapper">
        <p id="servicesSectionText">{t("servicesSection.text")}</p>
        <Button
          label={t("servicesSection.button")}
          className="servicesSectionButton soundElementList"
          onClick={() => animatedNavigate("/services")}
        />
      </div>
    </div>
  );
};

export default ServicesSection;
