import "./ArtistSeparator.scss";

const ArtistSeparator = () => {
  const artistList = [
    "TOXYBLUE",
    "U.NOESCP",
    "ILLUMINATEK",
    "MEDOK",
    "STOWN",
    "GEOTIK",
    "2DGAZ",
    "MYSTIMORPHIK",
    "TERAPEUTEK",
  ];

  const loopingArtistList = [...artistList, ...artistList];

  return (
    <section className="artistSeparator">
      <div className="scrolling-wrapper">
        {loopingArtistList.map((artist, index) => (
          <span className="artistName" key={index}>
            {artist}
            {"\u00A0+\u00A0"}
          </span>
        ))}
      </div>
    </section>
  );
};

export default ArtistSeparator;
