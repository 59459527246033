import "./ServiceCard.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../../components/Utils/Button/Button";
import Modal from "../../../../components/Utils/Modal/Modal";
import ServicesType from "../../../../types/Service";
import getImageUrl from "../../../../Utils/Services/img/imgFetch";
import ModalContent from "./ModalContent/ModalContent";
interface ServicesCardProps {
  service: ServicesType;
}

const ServicesCard: React.FC<ServicesCardProps> = (service) => {
  const { t } = useTranslation();

  return (
    <div className="serviceCard">
      <img
        src={getImageUrl("services/" + service.service.image)}
        alt="description"
      />
      <div className="content">
        <div className="name">{service.service.name}</div>
        <div className="description"> {service.service.title}</div>

        <Modal
          modalTitle={service.service.name}
          modalContent={
            <ModalContent
              description={service.service.description}
              image={service.service.image}
            />
          }
          modalCancel={false}
          modalButton={({ onClick }) => (
            <Button
              label={t("services.button")}
              onClick={onClick}
              className="buttonService"
              width={100}
              height={40}
              padding="0px 10px"
              margin="0 0 0 auto"
              style="small"
            />
          )}
          modalFooterButton={<></>}
        />
      </div>
    </div>
  );
};

export default ServicesCard;
