import "./ServicesPage.scss";

import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import useAnimation from "../../../hooks/useAnimation";
import ServicesType from "../../../types/Service";
import ServiceCard from "./ServiceCard/ServiceCard";

interface ServicesPageProps {}

const ServicesPage: React.FC<ServicesPageProps> = () => {
  const { t } = useTranslation();
  const { animateFadeInWithMove } = useAnimation();

  const servicesRefs = useRef<(HTMLDivElement | null)[]>([]);

  const servicesList: ServicesType[] = t("services.list", {
    returnObjects: true,
  });

  useEffect(() => {
    // Appliquer l'animation après le chargement des données
    if (servicesList && servicesList.length > 0) {
      servicesRefs.current.forEach((ref) => {
        // Assurez-vous que ref n'est pas null avant de l'appeler
        if (ref) {
          animateFadeInWithMove(
            ref as HTMLElement,
            0.2,
            ref as HTMLElement,
            "top 100%",
          );
        }
      });
    }
  }, [servicesList, animateFadeInWithMove]);

  return (
    <div className="servicesPageDiv">
      {/* <Button label="Filter" className="filterButton" icon={<Filter />} /> */}

      <div className="servicesList">
        {servicesList &&
          servicesList.map((service, index) => (
            <div
              ref={(el) => (servicesRefs.current[index] = el)}
              key={service.name}
            >
              <ServiceCard key={service.name} service={service} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ServicesPage;
