import "./Footer.scss";

import React from "react";

import { Facebook, Instagram, LogoTypo } from "../../assets/svg/svgIcons";
import { SOCIAL_LINKS } from "../../config/socials";
import useAnimatedNavigate from "../../hooks/useAnimatedNavigate";
import Button from "../Utils/Button/Button";

const Footer = () => {
  const animatedNavigate = useAnimatedNavigate();
  return (
    <section className="footer">
      <LogoTypo className="logoFooter" />
      <div className="footerText">
        <p>© 2024 FALC&apos;OHM</p>
        <Button
          label={"Terms of use"}
          onClick={() => animatedNavigate("/terms")}
          style="transparent"
        />
      </div>
      <div className="footerIcons">
        <Facebook
          className="facebook"
          onClick={() => window.open(SOCIAL_LINKS.facebook)}
        />
        <Instagram
          className="instagram"
          onClick={() => window.open(SOCIAL_LINKS.instagram)}
        />
      </div>
    </section>
  );
};

export default Footer;
