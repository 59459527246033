import "./Events.scss";

import React, { useEffect, useRef } from "react";

import Card from "../../components/Card/Card";
import ScrollingLineGsap from "../../components/Utils/ScrollingLineGsap/ScrollingLineGsap";
import useAnimation from "../../hooks/useAnimation";
import useWindowWidth from "../../hooks/useWindowWidth";
import getImageUrl from "../../Utils/Services/img/imgFetch";
import { eventsList } from "./eventsList";

const Events = () => {
  const { isMobile } = useWindowWidth();
  const eventsRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { animateFadeInWithMove } = useAnimation();

  useEffect(() => {
    eventsRefs.current.forEach((ref) => {
      if (ref) {
        animateFadeInWithMove(
          ref as HTMLElement,
          0.2,
          ref as HTMLElement,
          "top 90%",
        );
      }
    });
  }, [animateFadeInWithMove]);

  const animationOptions = {
    start: "top +=200",
    end: isMobile ? "bottom+=100% top" : "bottom+=4200 bottom",
    duration: 600,
    scrub: 1,
    markers: false,
  };

  return (
    <div className="eventsPage">
      <div className="header">
        <h1>Events</h1>
      </div>

      <div className="timeline">
        <ScrollingLineGsap
          animationOptions={animationOptions}
          svg={
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 100% 100%"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M55.1339 1C55.1339 1 121.513 450.041 55.1339 809.685C-11.245 1169.33 -6.7525 1262.54 55.1339 1618.37C117.02 1974.2 132.992 2102.58 55.1339 2427.05C-22.7239 2751.53 -6.7522 2906.29 55.1339 3235.74C117.02 3565.19 117.52 3718.78 55.1339 4055.86C-7.25257 4392.93 -26.2165 4562.93 55.1339 4875.97C136.484 5189.01 125.506 5394.19 55.1339 5696.09C-15.2382 5997.99 137.75 6193.2 55.1339 6516.21C-27.4819 6839.21 81.353 6995.73 106.806 7293.53C132.26 7591.33 84.0787 7692.15 56.8955 7916.68C29.7122 8141.2 24.9537 8198.06 64.3816 8368.06C103.809 8538.06 113.292 8613.69 64.3816 8830C64.3816 8830 22.5 9044 64.3816 9214.5C106.263 9385 107 9472 64.3816 9612.5C21.7631 9753 18 9909.5 64.3816 10089C110.763 10268.5 121 10416 64.3816 10592.5C7.76315 10769 -17.5 10979.5 64.3816 11204C146.263 11428.5 117.5 11663 64.3816 11950C11.2631 12237 -5.5 12508.5 64.3816 12824.5"
                stroke="#72D6E3"
                strokeWidth="30"
                strokeLinecap="round"
              />
            </svg>
          }
          className="lineEvents"
        />
        <div className="timelineCards">
          {eventsList.map((event, index) => (
            <div
              key={index}
              className={`timeline-event ${index % 2 === 0 ? "left" : "right"}`}
              ref={(el) => (eventsRefs.current[index] = el)}
            >
              <Card className="content">
                <h2>{event.title}</h2>
                <p>{event.date}</p>
                <p>{event.location}</p>
                {event.image ? (
                  <img
                    className="eventImage"
                    src={getImageUrl("events/" + event.image)}
                    alt={event.title}
                  />
                ) : null}
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Events;
