import "./SystemsPage.scss";

import React from "react";

import { Stack4Subs, Stack12Subs } from "../../../assets/svg/svgIcons";
import SoundElement from "./SoundElement/SoundElement";

interface SystemsPageProps {}

const SystemsPage: React.FC<SystemsPageProps> = () => {
  const equipmentList1 = [
    {
      nom: "6 x SI2000 Tops",
      partHovered: "aigu",
    },
    {
      nom: "6 x SI2000 Kicks",
      partHovered: "mid",
    },
    {
      nom: '12 MTH4654 21"',
      partHovered: "sub",
    },
    {
      nom: "Lab Gruppen Amplification",
    },
    {
      nom: "Soundcraft for Monitoring",
    },
    {
      nom: "All cabling",
    },
    {
      nom: "1 Sound Engineer",
    },
  ];

  const equipmentList2 = [
    {
      nom: "2 x SI2000 Tops",
      partHovered: "aigu",
    },
    {
      nom: "2 x SI2000 Kicks",
      partHovered: "mid",
    },
    {
      nom: '4 MTH4654 21"',
      partHovered: "sub",
    },
    {
      nom: "Lab Gruppen Amplification",
    },
    {
      nom: "Soundcraft for Monitoring",
    },
    {
      nom: "All cabling",
    },
    {
      nom: "1 Sound Engineer",
    },
  ];

  return (
    <div className="systemsPageDiv">
      <div className="systemsList">
        <SoundElement
          equipmentList={equipmentList1}
          svg={<Stack12Subs className="stack12Subs" />}
          className="stack12Subs"
          title="12 Subs System"
        />
        <SoundElement
          equipmentList={equipmentList2}
          svg={<Stack4Subs className="stack4Subs" />}
          className="stack4Subs"
          title="4 Subs System"
        />
      </div>
    </div>
  );
};

export default SystemsPage;
