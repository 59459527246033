import React from "react";

import { breakpoints } from "../../config/Breakpoints";
import useWindowWidth from "../../hooks/useWindowWidth";
import DesktopNavbar from "./DesktopNavbar/DesktopNavbar";
import MobileNavbar from "./MobileNavbar/MobileNavbar";

const Navbar: React.FC = () => {
  const { windowWidth } = useWindowWidth();

  return (
    <>
      {windowWidth > breakpoints["breakpoint-md"] ? (
        <>
          <DesktopNavbar />
        </>
      ) : (
        <MobileNavbar />
      )}
    </>
  );
};

export default React.memo(Navbar);
