import "./ProductCard.scss";

import React from "react";

import ProductType from "../../../../types/Product";

interface ProductCardProps {
  product: ProductType;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  return (
    <div className="productCard">
      <img src={product.image} alt={product.name} />
      <div className="productInfo">
        <div className="productName">{product.name}</div>
        <div className="productStock">Stock : {product.stock}</div>
        <div className="productCategory">Catégorie : {product.category}</div>
      </div>
    </div>
  );
};

export default ProductCard;
