import "./Carrousel.scss";

import React, { useEffect, useRef, useState } from "react";

import getImageUrl from "../../../Utils/Services/img/imgFetch";

interface CarrouselProps {
  imgArray: string[];
  className?: string;
  title: string;
  defaultSlide?: number;
}

const Carrousel: React.FC<CarrouselProps> = ({
  imgArray,
  className = "",
  title,
  defaultSlide = 0,
}) => {
  const [currentSlide, setCurrentSlide] = useState(defaultSlide);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [slideWidths, setSlideWidths] = useState<number[]>([]);

  // Drag on touch devices
  const [dragStart, setDragStart] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const updateWidths = () => {
      const widths = itemRefs.current.map((ref) =>
        ref?.clientWidth ? ref?.clientWidth + 20 : 0,
      );
      setSlideWidths(widths);
    };

    window.addEventListener("resize", updateWidths);
    const imageElements = itemRefs.current.map(
      (ref) => ref?.querySelector("img"),
    );
    imageElements.forEach((img) => img?.addEventListener("load", updateWidths));

    updateWidths();

    return () => {
      window.removeEventListener("resize", updateWidths);
      imageElements.forEach(
        (img) => img?.removeEventListener("load", updateWidths),
      );
    };
  }, []);

  // Update the position of the carousel when the current slide changes
  useEffect(() => {
    if (slideWidths.length > 0 && wrapperRef.current) {
      const containerWidth = wrapperRef.current.clientWidth;
      const totalWidths = slideWidths.reduce((acc, width) => acc + width, 0);
      let totalOffset = 0;

      if (currentSlide !== 0) {
        const prevSlidesWidth = slideWidths
          .slice(0, currentSlide)
          .reduce((acc, width) => acc + width, 0);
        const currentSlideWidth = slideWidths[currentSlide];
        totalOffset = prevSlidesWidth + currentSlideWidth / 2;
      }

      const centerOffset = containerWidth / 2 - totalOffset;
      const maxOffset = totalWidths - containerWidth / 2;
      let offsetToApply = Math.min(centerOffset, maxOffset);
      if (currentSlide === 0) {
        offsetToApply = 0;
      }
      wrapperRef.current.style.transform = `translateX(${offsetToApply}px)`;
    }
  }, [slideWidths, currentSlide]);

  const nextSlide = () => {
    const newIndex = (currentSlide + 1) % imgArray.length;
    setCurrentSlide(newIndex);
  };

  const prevSlide = () => {
    let newIndex = currentSlide - 1;
    if (newIndex < 0) {
      newIndex = imgArray.length - 1;
    }
    setCurrentSlide(newIndex);
  };

  // Go to a specific slide ( used with the navigation indicators)
  const goToSlide = (index: number) => {
    setCurrentSlide(index);
  };

  const renderNavigationIndicators = () => {
    return imgArray.map((_, index) => (
      <button
        key={index}
        className={`indicator ${currentSlide === index ? "active" : ""}`}
        onClick={() => goToSlide(index)}
        aria-label={`Go to slide ${index + 1}`}
      />
    ));
  };

  // Handle swipe on touch devices
  const handleSwipe = (startX: number, endX: number) => {
    const threshold = 50;
    const diffX = endX - startX;

    if (Math.abs(diffX) > threshold) {
      if (diffX > 0) {
        prevSlide();
      } else {
        nextSlide();
      }
    }
  };

  const handleDragStart = (position: number) => {
    setDragStart(position);
    setIsDragging(true);
  };

  const handleDragMove = (position: number) => {
    if (
      isDragging &&
      dragStart !== null &&
      slideWidths.length > 0 &&
      wrapperRef.current
    ) {
      const dragDistance = (position - dragStart) * 2;

      const containerWidth = wrapperRef.current.clientWidth;
      const totalWidths = slideWidths.reduce((acc, width) => acc + width, 0);
      let totalOffset = 0;

      if (currentSlide !== 0) {
        const prevSlidesWidth = slideWidths
          .slice(0, currentSlide)
          .reduce((acc, width) => acc + width, 0);
        const currentSlideWidth = slideWidths[currentSlide];
        totalOffset = prevSlidesWidth + currentSlideWidth / 2;
      }

      const centerOffset = containerWidth / 2 - totalOffset;
      const maxOffset = totalWidths - containerWidth / 2;
      let offsetToApply = Math.min(centerOffset, maxOffset);
      if (currentSlide === 0) {
        offsetToApply = 0;
      }
      if (wrapperRef.current) {
        wrapperRef.current.style.transform = `translateX(${
          offsetToApply + dragDistance
        }px)`;
      }
    }
  };

  const handleDragEnd = (position: number) => {
    setIsDragging(false);
    if (dragStart) {
      handleSwipe(dragStart, position);
      setDragStart(null);
    }
  };

  useEffect(() => {
    const onTouchStart = (e: TouchEvent) => {
      handleDragStart(e.touches[0].clientX);
    };

    const onTouchMove = (e: TouchEvent) => {
      handleDragMove(e.touches[0].clientX);
    };

    const onTouchEnd = (e: TouchEvent) => {
      handleDragEnd(e.changedTouches[0].clientX);
    };

    const onMouseDown = (e: MouseEvent) => {
      handleDragStart(e.clientX);
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e: MouseEvent) => {
      handleDragMove(e.clientX);
    };

    const onMouseUp = (e: MouseEvent) => {
      handleDragEnd(e.clientX);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    const wrapper = wrapperRef.current;
    wrapper?.addEventListener("touchstart", onTouchStart, { passive: true });
    wrapper?.addEventListener("touchmove", onTouchMove, { passive: false });
    wrapper?.addEventListener("touchend", onTouchEnd);
    wrapper?.addEventListener("mousedown", onMouseDown);

    return () => {
      wrapper?.removeEventListener("touchstart", onTouchStart);
      wrapper?.removeEventListener("touchmove", onTouchMove);
      wrapper?.removeEventListener("touchend", onTouchEnd);
      wrapper?.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [handleDragStart, handleDragMove, handleDragEnd]);

  return (
    <section id="carrousel-section" className={`carrouselMobile ${className}`}>
      <h1>{title}</h1>
      <div className="carouselContainer">
        <div className="carouselWrapper" ref={wrapperRef}>
          {imgArray.map((img, index) => (
            <div
              className="carouselItem"
              key={index}
              ref={(el) => (itemRefs.current[index] = el)}
              onClick={() => goToSlide(index)}
            >
              <img src={getImageUrl(img)} alt={`Carrousel ${index}`} />
            </div>
          ))}
        </div>
        <button onClick={prevSlide} className="prevControl"></button>
        <button onClick={nextSlide} className="nextControl"></button>
      </div>
      <div className="carouselIndicators">{renderNavigationIndicators()}</div>
    </section>
  );
};

export default Carrousel;
