import "./Agenda.scss";

import React, { useEffect, useState } from "react";

import useLoadingStore from "../../../stores/LoadingStore";
import EventType from "../../../types/Event";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
import getImageUrl from "../../../Utils/Services/img/imgFetch";
import EventsCard from "./EventsCard/EventsCard";

interface AgendaProps {}

const Agenda: React.FC<AgendaProps> = () => {
  const [eventsList, setProductsList] = useState<EventType[] | undefined>();
  const { setLoading } = useLoadingStore();

  const [error, setError] = useState<string | undefined>();
  useEffect(() => {
    setLoading("agenda", true);
    const fetchData = async () => {
      const eventsData = await appFetch("/api/events/getFalcohmEvents");
      if (eventsData) {
        // filter events with date > today - 1 day
        const today = new Date().getTime() - 86400000;
        const filteredEvents = eventsData.filter(
          (event: EventType) => new Date(event.date).getTime() > today,
        );

        setProductsList(filteredEvents);
      } else {
        setError("Error fetching events");
      }
      setLoading("agenda", false);
    };

    fetchData();
  }, []);

  return (
    <div className="agendaDiv" id="agenda-section">
      <div className="agendaHeader">
        <h1>Agenda</h1>
        <img src={getImageUrl("agenda/speaker.png")} alt="agenda" />
      </div>

      <div className="eventsList">
        {eventsList &&
          eventsList.length > 0 &&
          eventsList.map((event) => (
            <EventsCard key={event.event_id} event={event} />
          ))}
        {eventsList && eventsList.length === 0 && (
          <div className="eventCard">
            <div className="eventName">Coming soon</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Agenda;
