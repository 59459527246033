import "./SoundHire.scss";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Stack12Subs } from "../../../assets/svg/svgIcons";
import Button from "../../../components/Utils/Button/Button";
import useAnimatedNavigate from "../../../hooks/useAnimatedNavigate";
import useAnimation from "../../../hooks/useAnimation";
import useWindowWidth from "../../../hooks/useWindowWidth";
import SoundElement from "../../Products/SystemsPage/SoundElement/SoundElement";

const SoundHire = () => {
  const { animateFadeInWithMove, animateWithScroll } = useAnimation();
  const { t } = useTranslation();
  const { isMobile } = useWindowWidth();

  const animatedNavigate = useAnimatedNavigate();

  const initAnimation = () => {
    const soundHireTitle = document.getElementById("soundHireTitle");
    const soundHireText = document.getElementById("soundHireText");
    if (soundHireTitle)
      animateFadeInWithMove(soundHireTitle, 0.6, soundHireTitle);
    if (soundHireText) animateFadeInWithMove(soundHireText, 0.6, soundHireText);
    const soundHireDiv = document.querySelector(".soundHireDiv .imageWrapper");
    if (soundHireDiv instanceof HTMLElement) {
      animateFadeInWithMove(soundHireDiv, 0.6, soundHireDiv);
      if (isMobile) {
        animateWithScroll({
          element: soundHireDiv,
          startX: 0,
          startY: 20,
          endX: 0,
          endY: -20,
          scrub: 1,
          startTrigger: "top bottom",
        });
      } else {
        animateWithScroll({
          element: soundHireDiv,
          startX: 70,
          startY: 10,
          endX: -20,
          endY: 0,
          scrub: 1,
          startTrigger: "top bottom",
        });
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(initAnimation, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <section className="soundHire" id="hire-section">
      <h1 id="soundHireTitle">{t("soundHire.title")}</h1>
      <p id="soundHireText">{t("soundHire.text")}</p>
      <div className="soundHireDiv" id="soundHireDiv">
        <SoundElement
          svg={<Stack12Subs className="stack12Subs" />}
          className="stack12Subs"
          button={
            <Button
              label={t("soundHire.button")}
              className="soundHireButton soundElementList"
              onClick={() => animatedNavigate("/systems")}
            />
          }
        />
      </div>
    </section>
  );
};

export default SoundHire;
