import "./ProductsPage.scss";

import React, { useEffect, useRef, useState } from "react";

import Spinner from "../../../components/Utils/Spinner/Spinner";
import useAnimation from "../../../hooks/useAnimation";
import ProductType from "../../../types/Product";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
import ProductCard from "./ProductCard/ProductCard";

interface ProductsPageProps {}

const ProductsPage: React.FC<ProductsPageProps> = () => {
  const [productsList, setProductsList] = useState<ProductType[] | undefined>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [error, setError] = useState<string | undefined>();
  const { animateFadeInWithMove } = useAnimation();
  const productRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    setIsLoaded(false);
    const fetchData = async () => {
      const productsData = await appFetch("/api/products/getFalcohmProducts");
      if (productsData) {
        setProductsList(productsData);
        setIsLoaded(true);
      } else {
        setError("Error fetching products");
        setIsLoaded(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Appliquer l'animation après le chargement des données
    if (productsList && productsList.length > 0) {
      productRefs.current.forEach((ref) => {
        // Assurez-vous que ref n'est pas null avant de l'appeler
        if (ref) {
          animateFadeInWithMove(
            ref as HTMLElement,
            0.2,
            ref as HTMLElement,
            "top 100%",
          );
        }
      });
    }
  }, [productsList, animateFadeInWithMove]);

  return (
    <div className="productsPageDiv">
      {/* <Button label="Filter" className="filterButton" icon={<Filter />} /> */}
      <div className="productsList">
        {productsList &&
          isLoaded &&
          productsList.map((product, index) => (
            <div
              ref={(el) => (productRefs.current[index] = el)}
              key={product.product_id}
            >
              <ProductCard product={product} />
            </div>
          ))}

        {!isLoaded && <Spinner />}
      </div>
      {error && <span>{error}</span>}
    </div>
  );
};

export default ProductsPage;
