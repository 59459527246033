export const eventsList = [
  {
    title: "FREE TO KSAAR #02 (FTK2)",
    date: "25.06.2018",
    location: "Le Cellier - Nivelles",
    image: "FTK2.jpg",
  },
  {
    title: "Free to Ksaar 3 (FTK3)",
    date: "23.03.2019",
    location: "La Localienne ASBL – Liège",
    image: "FTK3.JPG",
  },
  {
    title: "Make It Burn & Lucid & Falcohm at Welcome Spring Festival",
    date: "24.04.2019",
    location: "Haut Parking Leclerq – Louvain-la-Neuve",
    image: "WSF1.JPG",
  },
  {
    title: "Casa Lab w/ Make It Burn x Falc'ohm System",
    date: "4.11.2019",
    location: "La Grande Casa – Louvain-La-Neuve",
    image: "casalab.jpg",
  },
  {
    title: "FTK City Tour: Baroque Edition",
    date: "16.11.2019",
    location: "Le Baroque Bar – Nivelles",
    image: "baroque.jpg",
  },
  {
    title: "Festival Cap Transition 2020",
    date: "9.03.2020",
    location: "Grand Place de Louvain – Louvain-La-Neuve",
    image: "cap_transition.jpg",
  },
  {
    title: "La MJ au parc",
    date: "31.07.2021",
    location: "Château Du Cheneau – Braine-l’Alleud",
    image: "MJ1.jpg",
  },
  {
    title: "La MJ au parc",
    date: "7.08.2021",
    location: "Maison des jeunes de Braine-l’Alleud",
    image: "MJ2.jpg",
  },
  {
    title: "La MJ au parc",
    date: "14.08.2021",
    location: "Skate Parc de Braine-l’Alleud",
    image: "MJ3.jpg",
  },
  {
    title: "Massif Festival 2021",
    date: "11.09.2021",
    location: "Château Du Cheneau – Braine-l’Alleud",
    image: "MassifFestival.jpg",
  },
  {
    title: "VÉLEKTRO CSE - by Make It Burn & Falc'ohm System",
    date: "9.03.2022",
    location: "Parking Sainte Barbe – Louvain-la-Neuve",
    image: "Velectro.jpg",
  },
  {
    title: "Soirée Techno LGBTQIA+",
    date: "24.03.2022",
    location: "La Turbean – ULB",
  },
  {
    title: "MAKE IT BURN X FALC'OHM SYSTEM @ WELCOME SPRING 2022",
    date: "20.04.2022",
    location: "Haut Parking Leclerq – Louvain-la-Neuve",
    image: "WSF2022.jpg",
  },
  {
    title: "ZINNEKE PARADE 2022",
    date: "14.05.22",
    location: "Place de la Bourse - Bruxelles",
    image: "Zinneke.jpeg",
  },
  {
    title: "RACCOON DYNASTY #2",
    date: "19.11.2022",
    location: "Espace Georges Truffaut – Liège",
    image: "Raccoon2.jpg",
  },
  {
    title: "FALC’OHM SYSTEM inside “CHEZ ZELLE”",
    date: "08.04.2023",
    location: "Chez Zelle asbl – Louvain-La-Neuve",
    image: "Zelle1.jpg",
  },
  {
    title: "WELCOME SPRING BOUM BOUM",
    date: "26.04.2023",
    location: "Haut Parking Leclerq – Louvain-la-Neuve",
    image: "WSF2023.jpg",
  },
  {
    title: "Piknik Electronik closing Season – United against racism",
    date: "19.08.23",
    location: "BBP Port Sud – Bruxelles",
    image: "PikNik.jpg",
  },
  {
    title: "MOSFET CLUB Open Air",
    date: "26.08.2023",
    location: "BBP Port Sud – Bruxelles",
    image: "MosfetOpenAir.jpg",
  },
  {
    title: "FALC’OHM SYSTEM inside “CHEZ ZELLE” #2",
    date: "02.09.2023",
    location: "Chez Zelle asbl – Louvain-La-Neuve",
    image: "Zelle2.jpg",
  },
  {
    title: "RACCOON DYNASTY 3.0",
    date: "21.10.2023",
    location: "Espace Georges Truffaut - Liège",
    image: "Raccoon3.jpg",
  },
  {
    title: "Halloween birthday party Gazmatek invite Falc’ohm",
    date: "31.10.2023",
    location: "Planet Park – Bruxelles",
    image: "HalweenBDayParty.jpg",
  },
  {
    title: "Saw-toothed by Ansatz",
    date: "17.10.2023",
    location: "Barrio Café – Bruxelles",
  },
  {
    title: "Mosfet Club at Imprimerie",
    date: "17.02.2024",
    location: "Imprimerie – Bruxelles",
    image: "MosfetImprimerie.jpg",
  },
  {
    title: "INTO THE RAVE / FALC’OHM VS GAZMATEK",
    date: "24.02.2024",
    location: "Imprimerie – Bruxelles",
    image: "IntoTheRave.jpg",
  },
  {
    title: "LOFT EXPERIENCE HUDDLE",
    date: "12.04.24",
    location: "Camping 58 – Bruxelles",
    image: "LoftExperience.jpg",
  },
  {
    title: "Scène électro by Kot&Mix @Welcome Spring ! Festival 2024",
    date: "17.04.2024",
    location: "Haut Parking Leclerq – Louvain-la-Neuve",
    image: "WSF2024.jpg",
  },
  {
    title: "Chill2Chill present the THERAPY XL – 12 Years Anniversary",
    date: "08.05.24",
    location: "Palais des Congrès – Liège",
  },
];
