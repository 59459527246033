import "./ModalContent.scss";

interface ModalContentProps {
  description: string;
  image: string;
}
const ModalContent = ({ description, image }: ModalContentProps) => {
  return (
    <div className="modalContent">
      <div className="imageContainer">
        {/* <img src={getImageUrl("services/" + image)} alt="description" /> */}
      </div>
      <span>{description}</span>
    </div>
  );
};

export default ModalContent;
