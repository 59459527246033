// ScrollingLineGsap.tsx
import "./ScrollingLineGsap.scss";

import React, { useRef, useState } from "react";

import useAnimation from "../../../hooks/useAnimation";

interface ScrollingLineGsapProps {
  svg: JSX.Element;
  className?: string;
  animationOptions: {
    start: string;
    end: string;
    duration: number;
    scrub: number;
    markers: boolean;
  };
}

const generateUniqueId = () =>
  `svg-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

const ScrollingLineGsap: React.FC<ScrollingLineGsapProps> = ({
  svg,
  className,
  animationOptions,
}) => {
  const { animateScrollingLine } = useAnimation();
  const [id] = useState(generateUniqueId()); // Générer un ID unique à la création du composant
  const pathRef = useRef<SVGPathElement>(null);

  animateScrollingLine(pathRef, animationOptions);

  const svgWithRef = React.cloneElement(svg, {
    id,
    children: React.cloneElement(svg.props.children, { ref: pathRef }),
  });

  return <div className={`svg-container ${className ?? ""}`}>{svgWithRef}</div>;
};

export default ScrollingLineGsap;
