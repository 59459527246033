import "./DesktopNavbar.scss";

import gsap from "gsap";
import React, { useEffect, useRef } from "react";

import { Logo } from "../../../assets/svg/svgIcons";
import useAnimatedNavigate from "../../../hooks/useAnimatedNavigate";
import useAnimation from "../../../hooks/useAnimation";
import DropdownNavbar from "../DropdownNavbar/DropdownNavbar";
import ElementNavBar, { LanguageButton } from "../ElementNavbar/ElementNavbar";
import navItems from "../NavItems";

const DesktopNavbar: React.FC = () => {
  const navbarRef = useRef<HTMLDivElement>(null);

  const { useHoverAnimation } = useAnimation();
  const barRef = useRef(null);

  useHoverAnimation([barRef]);
  const animatedNavigate = useAnimatedNavigate();

  const handleClick = () => {
    animatedNavigate("/");
  };

  useEffect(() => {
    setTimeout(() => {
      if (navbarRef.current) {
        gsap.fromTo(
          navbarRef.current,
          { y: -50, opacity: 0 },
          { y: 0, opacity: 1, duration: 1, ease: "power1.out" },
        );
      }
    }, 200);
  }, []);

  return (
    <nav
      className="desktopNavbar"
      role="navigation"
      aria-label="Site Navigation"
      ref={navbarRef}
    >
      <div className="navbarLeft">
        <Logo className="navTitle" onClick={handleClick} />

        {navItems
          .filter((item) => item.type !== "dropdown")
          .map((item) => (
            <ElementNavBar
              key={item.href || ""}
              href={item.href || ""}
              texts={item.texts}
              page={item.page ?? ""}
              className={item.className}
            />
          ))}
      </div>
      <div className="navbarRight">
        {navItems
          .filter((item) => item.type === "dropdown")
          .map((item) => (
            <DropdownNavbar
              key={item.texts}
              title={item.texts}
              subItems={item.subItems || []}
            />
          ))}

        <LanguageButton />
      </div>
    </nav>
  );
};

export default React.memo(DesktopNavbar);
