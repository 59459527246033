import "./Blob.scss";

import React from "react";

const Blob = () => {
  return (
    <div className="blobDiv">
      <div className={`blob blob-0`}></div>
      <div className={`blob blob-1`}></div>
    </div>
  );
};

export default Blob;
