const navItems = [
  {
    href: "#about-section",
    page: "/",
    texts: "About",
    className: "aboutUss",
    type: "button",
  },
  {
    href: "#hire-section",
    page: "/",
    texts: "Hire",
    className: "hiresection",
    type: "button",
  },
  {
    href: "#contact-section",
    page: "/",
    texts: "Contact",
    className: "contact",
    type: "button",
  },
  {
    href: "/events",
    page: "/events",
    texts: "Events",
    type: "button",
    className: "products",
  },

  {
    href: "/products",
    page: "/",
    texts: "Explore",
    type: "dropdown",
    className: "products",
    subItems: [
      {
        href: "/products",
        page: "/products",
        texts: "Products",
        className: "subProduct",
      },
      {
        href: "/services",
        page: "/services",
        texts: "Services",
        className: "subProduct",
      },
      {
        href: "/systems",
        page: "/systems",
        texts: "Systems",
        className: "subProduct",
      },
    ],
  },
];

export default navItems;
