import "./EventsCard.scss";

import React from "react";

import EventType from "../../../../types/Event";

interface EventsCardProps {
  event: EventType;
}

const EventsCard: React.FC<EventsCardProps> = ({ event }) => {
  // Formatage de la date pour l'affichage
  const formattedDate = new Date(event.date).toLocaleDateString("fr-FR", {
    month: "short",
    day: "numeric",
  });

  const formatTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(":");
    const hoursInt = parseInt(hours, 10);
    const suffix = hoursInt >= 12 ? " pm" : " am";
    return `${hoursInt}${suffix}`;
  };

  return (
    <div className="eventCard">
      {/* <img src={"/api/images/" + event.picture} alt={event.name} /> */}
      <div className="eventDate">{formattedDate}</div>
      <div className="eventName">{event.name}</div>
      <div className="eventSchedule">
        {" "}
        {formatTime(event.start_at)} - {formatTime(event.end_at)}
      </div>
      <div className="eventLocation"> {event.location}</div>
    </div>
  );
};

export default EventsCard;
