import "./ContactForm.scss";

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import useAnimation from "../../hooks/useAnimation";
import appFetch from "../../Utils/Services/Fetch/appFetch";
import Button from "../Utils/Button/Button";
import Dropdown, { ValueList } from "../Utils/Dropdown/Dropdown";
import Blob from "./Blob/Blob";

const initialFormState = {
  name: "",
  email: "",
  message: "",
  phone: "",
};

const ContactForm = () => {
  const [formFields, setFormFields] = useState(initialFormState);
  const [selectedSubject, setSelectedSubject] = useState<ValueList | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const subjectOptions: ValueList[] = [
    { id: "general", name: t("contact.subjectOptions.quote") },
    { id: "support", name: t("contact.subjectOptions.information") },
    { id: "feedback", name: t("contact.subjectOptions.feedback") },
  ];
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDropdownChange = (value: ValueList) => {
    setSelectedSubject(value);
    setFormFields((prev) => ({
      ...prev,
      subject: value.name,
    }));
  };

  const handleSubmit = async () => {
    // Logique pour envoyer les données du formulaire

    setIsLoading(true);
    const sendData = async () => {
      const { name, email, phone, message } = formFields;
      const subject = selectedSubject ? selectedSubject.name : "";

      // Appel à appFetch pour envoyer les données
      const result = await appFetch("/api/contacts/sendMessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          message,
          subject,
        }),
      });

      if (result) {
        toast.success("Message envoyé avec succès!");
        setFormFields(initialFormState);
        setSelectedSubject(undefined);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    sendData().catch((error) => {
      console.error("Failed to send message:", error);
      toast.error("Une erreur est survenue lors de l'envoi du message.");
    });
  };

  const { animateFadeInWithMove, animateWithScroll } = useAnimation();

  const initAnimation = () => {
    const section = document.querySelectorAll(".contactForm");

    animateFadeInWithMove(section, 0.6, section);
    animateWithScroll({
      element: ".blobDiv",
      endY: 0,
      startY: -200,
      startTrigger: "top+=10% bottom",
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(initAnimation, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className="contactFormDiv" id="contact-section">
      <Blob />
      <section className="contactForm">
        <h1>{t("contact.title")}</h1>
        <form onSubmit={handleSubmit}>
          <Dropdown
            value={selectedSubject}
            setValue={handleDropdownChange}
            valueList={subjectOptions}
          />
          <input
            type="text"
            name="name"
            placeholder={t("contact.namePlaceholder")}
            value={formFields.name}
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder={t("contact.emailPlaceholder")}
            value={formFields.email}
            onChange={handleInputChange}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder={t("contact.phonePlaceholder")}
            value={formFields.phone}
            onChange={handleInputChange}
          />

          <textarea
            name="message"
            placeholder={t("contact.messagePlaceholder")}
            value={formFields.message}
            onChange={handleInputChange}
            required
          ></textarea>
          <Button
            label={t("contact.sendButton")}
            className="contactFormButton"
            onClick={handleSubmit}
            isLoading={isLoading}
          />
        </form>
      </section>
    </div>
  );
};

export default ContactForm;
