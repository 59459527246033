/// <reference types="vite-plugin-svgr/client" />

import "./App.scss";
import "./i18n/i18n";

import { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Contact from "./pages/Contact/Contact";
import Events from "./pages/Events/Events";
import Home from "./pages/Home/Home";
import Products from "./pages/Products/Products";
import TermsOfUses from "./pages/TermsOfUses/TermsOfUses";

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

function App() {
  return (
    <div className="App">
      <header>
        <Navbar />
      </header>
      <Routes>
        <>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products page={"Products"} />} />
          <Route path="/services" element={<Products page={"Services"} />} />
          <Route path="/systems" element={<Products page={"Systems"} />} />
          <Route path="/terms" element={<TermsOfUses />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/events" element={<Events />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      </Routes>
      <Footer />
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
}

export default AppWrapper;
