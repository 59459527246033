import "./Hero.scss";

import { gsap } from "gsap";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../components/Utils/Button/Button";
import useAnimation from "../../../hooks/useAnimation";
import getImageUrl from "../../../Utils/Services/img/imgFetch";

function Hero() {
  const { t } = useTranslation();

  const images = ["hero1.jpg", "hero2.jpg", "hero3.jpg"];
  const words: string[] = t("hero.words", { returnObjects: true }) as string[];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const wordRef = useRef(null);
  const heroTextRef = useRef(null);
  const buttonRef = useRef(null);
  const imageRefs = useRef<(HTMLImageElement | null)[]>([]);
  const { scrollToSection } = useAnimation();

  const animateWord = useCallback(() => {
    gsap.fromTo(
      wordRef.current,
      { y: 100, opacity: 0 },
      { y: 0, opacity: 0.45, duration: 2.6, ease: "elastic.out" },
    );
  }, []);

  const animateImages = useCallback(() => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    // Animation de fade out pour l'image courante
    gsap.to(imageRefs.current[currentImageIndex], { opacity: 0, duration: 1 });

    // Animation de zoom out et d'éclaircissement pour la prochaine image
    if (imageRefs.current[nextIndex]) {
      gsap.fromTo(
        imageRefs.current[nextIndex],
        { scale: 1.1, opacity: 0, filter: "brightness(0)" },
        {
          scale: 1,
          opacity: 0.45,
          filter: "brightness(1)",
          duration: 0.8,
          ease: "power1.out",
        },
      );
    }

    animateWord();
    setCurrentImageIndex(nextIndex);
  }, [currentImageIndex, images.length, animateWord]);

  useEffect(() => {
    const timer = setTimeout(animateImages, 3500);
    return () => clearTimeout(timer);
  }, [animateImages]);

  const animateHeroText = useCallback(() => {
    if (heroTextRef.current) {
      gsap.fromTo(
        heroTextRef.current,
        { y: 30, opacity: 0 },
        { y: 0, opacity: 1, duration: 2.5, ease: "elastic.out" },
      );
    }
  }, []);

  useEffect(() => {
    gsap.to(".overflow-wrap", {
      scrollTrigger: {
        trigger: ".hero",
        start: "top top",
        end: "center+=20% top",
        scrub: 1,
      },
      borderBottomLeftRadius: "70px",
      borderBottomRightRadius: "70px",
    });
  }, []);

  const animateFirstImage = useCallback(() => {
    if (imageRefs.current[0]) {
      gsap.fromTo(
        imageRefs.current[0],
        { scale: 1.1, opacity: 0, filter: "brightness(0)" },
        {
          scale: 1,
          opacity: 0.45,
          filter: "brightness(1)",
          duration: 0.8,
          ease: "power1.out",
        },
      );
    }
  }, []);

  const animateButton = useCallback(() => {
    if (buttonRef.current) {
      gsap.fromTo(
        buttonRef.current,
        { width: 0, opacity: 0, padding: "20px 0" },
        {
          width: "300px",
          opacity: 1,
          padding: "20px 80px",
          duration: 1.2,
          ease: "power2.out",
        },
      );
    }
  }, []);

  useEffect(() => {
    animateFirstImage();
    animateWord();
    animateHeroText();
    animateButton();
  }, [animateFirstImage]);

  return (
    <section className="hero">
      <div className="slider">
        <div className="overflow-wrap">
          {images.map((image, index) => (
            <img
              key={image}
              ref={(el) => (imageRefs.current[index] = el)}
              src={getImageUrl(image)}
              alt="hero"
              className="hero-image"
              style={{ opacity: index === 0 ? 0.45 : 0 }}
            />
          ))}
        </div>

        <div className="hero-text" ref={heroTextRef}>
          <span className="magical-touch">{t("hero.magical_touch")}</span>
          <br></br>
          <div ref={wordRef}>
            <span className="wordAnimation>">{words[currentImageIndex]}</span>
          </div>
          <Button
            ref={buttonRef}
            label="Agenda"
            onClick={() => scrollToSection("agenda-section")}
          />
        </div>
      </div>
    </section>
  );
}

export default Hero;
