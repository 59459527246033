import "./Home.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import ContactForm from "../../components/ContactForm/ContactForm";
import ScrollingLineGsap from "../../components/Utils/ScrollingLineGsap/ScrollingLineGsap";
import useWindowWidth from "../../hooks/useWindowWidth";
import AboutUs from "./AboutUs/AboutUs";
import Agenda from "./Agenda/Agenda";
import ArtistSeparator from "./ArtistSeparator/ArtistSeparator";
import Carrousel from "./Carrousel/Carrousel";
import CarrouselDesktop from "./CarrouselDesktop/CarrouselDesktop";
import Hero from "./Hero/Hero";
import ServicesSection from "./ServicesSection/ServicesSection";
import SoundHire from "./SoundHire/SoundHire";

const Home: React.FC = () => {
  const { isTabelet, isMobile } = useWindowWidth();
  const { t } = useTranslation();

  const carouselTitle = t("carousel.title");

  const animationOptions = {
    start: "top-=10% +=400 ",
    end: isMobile ? "bottom+=100% top" : "bottom top",
    duration: 4,
    scrub: 1,
    markers: false,
  };

  return (
    <div className="home">
      <Hero />
      <ScrollingLineGsap
        animationOptions={animationOptions}
        className="lineAgenda"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1951"
            height="1419"
            viewBox="0 0 1951 1419"
            fill="none"
          >
            <path
              d="M15 15.5C15 15.5 242.719 77.1383 425.922 278.319C609.124 479.499 619.353 733.751 537.213 917.387C455.073 1101.02 229.422 1165.91 119.015 1056.5C14.545 952.981 15 806.096 212.756 677.176C410.512 548.256 812.694 572.531 1041.88 689.668C1273.02 807.808 1264.46 895.985 1384.31 1002.14C1479.28 1086.26 1626.16 897.697 1776.4 979.026C1874.42 1034.24 1752.03 1148.77 1878.7 1231.57C2005.37 1314.38 1878.7 1336.4 1878.7 1403.57"
              stroke="#72D6E3"
              strokeWidth="30"
              strokeLinecap="round"
            />
          </svg>
        }
      />
      <Agenda />

      {isTabelet ? (
        <Carrousel
          imgArray={[
            "popularParties/popularParties1.png",
            "popularParties/popularParties3.jpg",
            "popularParties/popularParties6.jpg",
            "popularParties/popularParties4.jpg",
            "popularParties/popularParties5.jpg",
            "popularParties/popularParties2.jpg",
            "popularParties/popularParties7.jpg",
          ]}
          title={carouselTitle}
        />
      ) : (
        <CarrouselDesktop
          imgArray={[
            "popularParties/popularParties1.png",
            "popularParties/popularParties3.jpg",
            "popularParties/popularParties6.jpg",
            "popularParties/popularParties4.jpg",
            "popularParties/popularParties5.jpg",
            "popularParties/popularParties2.jpg",
            "popularParties/popularParties7.jpg",
          ]}
          title={carouselTitle}
        />
      )}

      <ArtistSeparator />
      <AboutUs />
      {/* <ScrollingLineGsap
        className="line"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2270"
            height="1132"
            viewBox="0 0 2270 1132"
            fill="none"
          >
            <path
              d="M2160.01 4C2094.01 130 1707 248.853 1582.5 266C1458 283.147 1523 390 1373 437.5C1231.88 482.188 929.001 553.5 667.001 422.5C405.001 291.5 526.822 659.263 361.001 679C-214.502 747.5 35.5 1005.5 260.504 1051C485.508 1096.5 547.608 1118.32 667.001 1040.5C734.503 996.5 777.992 936.406 756.001 861.5C737.24 797.594 687.619 744.585 622.001 756C559.245 766.917 541.533 827.546 529.001 890C498.609 1041.46 727.54 1066.3 882.001 1068.5C987.998 1070.01 1094.72 1085.38 1142 990.5C1162.49 949.372 1173.14 917.556 1158.5 874C1134.23 801.795 1051.33 778.836 994.003 829C946.246 870.791 962.003 912 979.501 959C1026.74 1085.89 1155.03 1073.15 1286 1081C1388.55 1087.15 1474.39 1119.55 1540 1040.5C1581.5 990.5 1582.17 923.496 1540 874C1505.48 833.478 1462.5 811 1410 833C1353.27 856.771 1350.42 947.787 1373 1005C1418.91 1121.3 1575.43 1124.09 1710.5 1101C1774.19 1090.11 1786 1081 1864.5 1040.5C1943 1000 1964 936 1884.5 874C1805 812 1743.92 851.519 1710.5 904C1674.29 960.857 1692.3 1025.25 1744 1068.5C1780.72 1099.21 1884.5 1101 1884.5 1101C1884.5 1101 2242 1124 2269.5 1124"
              stroke="#72D6E3"
              strokeWidth="15"
            />
          </svg>
        }
      /> */}
      <SoundHire />

      <ServicesSection />
      <ContactForm />
    </div>
  );
};

export default Home;
