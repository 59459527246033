import "./Contact.scss";

import React from "react";

import ContactForm from "../../components/ContactForm/ContactForm";

const Contact = () => {
  return (
    <div className="contactDiv">
      <ContactForm />
    </div>
  );
};

export default Contact;
