import "./MobileNavbar.scss";

import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

import { LogoTypo } from "../../../assets/svg/svgIcons";
import useAnimatedNavigate from "../../../hooks/useAnimatedNavigate";
import ElementNavBar, { LanguageButton } from "../ElementNavbar/ElementNavbar";
import navItems from "../NavItems";

const MobileNavbar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const animatedNavigate = useAnimatedNavigate();
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };
  const handleClick = () => {
    animatedNavigate("/");
  };

  useEffect(() => {
    // put filter blur on class home
    const home = document.querySelector(".home");
    const product = document.querySelector(".productsDiv");
    if (home && collapsed) {
      home.classList.add("blur");
    }
    if (product && collapsed) {
      product.classList.add("blur");
    }
    return () => {
      if (home) {
        home.classList.remove("blur");
      }
      if (product) {
        product.classList.remove("blur");
      }
    };
  }, [collapsed]);

  return (
    <nav
      className={`mobileNavbar`}
      role="navigation"
      aria-label="Site Navigation"
    >
      <button className="navbar-toggler" type="button">
        <span className="navbar-brand" onClick={handleClick}>
          <LogoTypo />
        </span>
        {collapsed ? (
          <FaTimes
            onClick={toggleNavbar}
            className="burger-close-btn"
            color="white"
          />
        ) : (
          <FaBars onClick={toggleNavbar} className="burger-open-btn" />
        )}
      </button>
      <div className={`navbar-collapse ${collapsed ? "show" : ""}`}>
        <ul className="navbar-nav">
          {navItems.map((item) => (
            <>
              <ElementNavBar
                key={item.href}
                href={item.href}
                texts={item.texts}
                page={item.page}
                className={item.className}
                onLinkClick={() => setCollapsed(false)}
              />
              {item.subItems?.map((subItem) => (
                <ElementNavBar
                  key={subItem.href}
                  href={subItem.href}
                  texts={subItem.texts}
                  page={subItem.page}
                  className={item.className}
                  onLinkClick={() => setCollapsed(false)}
                />
              ))}
            </>
          ))}
          <LanguageButton />
        </ul>
      </div>
    </nav>
  );
};

export default React.memo(MobileNavbar);
