import { gsap } from "gsap";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAnimation from "./useAnimation";

const useAnimatedNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { slideOutToLeft, slideInFromRight } = useAnimation();

  const animatedNavigate = useCallback(
    (path: string, onComplete?: () => void) => {
      // Ajout d'un argument facultatif
      if (location.pathname !== path) {
        slideOutToLeft(document.body, 0.5, () => {
          navigate(path);
          window.scrollTo(0, 0);

          slideInFromRight(document.body, 0.5, () => {
            gsap.set(document.body, { clearProps: "all" });
            if (onComplete) onComplete();
          });
        });
      }
    },
    [navigate, location, slideOutToLeft, slideInFromRight],
  );

  return animatedNavigate;
};

export default useAnimatedNavigate;
