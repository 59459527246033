import "./SoundElement.scss";

import React, { useEffect } from "react";

import useAnimation from "../../../../hooks/useAnimation";

interface EquipmentItem {
  nom: string;
  partHovered?: string;
}

interface SoundElementProps {
  equipmentList?: EquipmentItem[];
  svg: React.ReactElement;
  className: string;
  button?: React.ReactElement;
  title?: string;
}

const SoundElement: React.FC<SoundElementProps> = ({
  equipmentList,
  svg,
  className,
  button,
  title,
}) => {
  const { animateFadeInWithMove } = useAnimation();

  useEffect(() => {}, []);

  const initAnimation = () => {
    const soundElementList = document.querySelectorAll(".soundElementList");
    const soundHirePictureList = document.querySelectorAll(".soundHirePicture");

    soundElementList.forEach((element) => {
      if (element instanceof HTMLElement) {
        animateFadeInWithMove(element, 0.6, element);
      }
    });
    soundHirePictureList.forEach((element) => {
      if (element instanceof HTMLElement) {
        animateFadeInWithMove(element, 0.6, element);
      }
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(initAnimation, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const changeColorSVGPath = (partHovered: string | undefined) => {
    // Réinitialiser les couleurs et la taille de tous les éléments
    document.querySelectorAll(`.${className} g`).forEach((el) => {
      const element = el as HTMLElement;
      element.style.transformOrigin = "center"; // Pour centrer le grossissement
      element.style.filter = "none";

      // Réinitialiser les styles des éléments polygon et polyline
      element
        .querySelectorAll("polygon, polyline, line, path, ellipse")
        .forEach((polyEl) => {
          const polyElement = polyEl as HTMLElement;
          polyElement.style.fill = "none"; // Réinitialiser la couleur de fond
          polyElement.style.stroke = "#FFFFFF"; // Réinitialiser la couleur de ligne
        });
    });

    // Réinitialiser les couleurs de la liste
    document.querySelectorAll(`.soundElementList li`).forEach((el) => {
      const element = el as HTMLElement;
      element.style.color = "#FFFFFF";
    });

    // Appliquer les modifications à l'élément survolé
    if (partHovered) {
      const svgPart = document.querySelector(
        `.${className} #${partHovered}`,
      ) as HTMLElement | null;
      if (svgPart) {
        // svgPart.style.transform = "scale(1.1)";
        svgPart.style.filter =
          "drop-shadow(0px 0px 15px rgba(114, 214, 227, 0.5))";
        // Modifier les styles des ligne
        svgPart
          .querySelectorAll("polygon, polyline, line, path, ellipse")
          .forEach((polyEl) => {
            const polyElement = polyEl as HTMLElement;
            polyElement.style.stroke = "#72d6e3";
          });
      }
    }

    if (partHovered) {
      const listItemHovered = document.querySelector(
        `.soundElementList .${partHovered}.${className}`,
      ) as HTMLElement | null;
      if (listItemHovered) {
        listItemHovered.style.color = "#72d6e3";
      }
    }
  };

  return (
    <div className="soundElement">
      {equipmentList && (
        <div>
          {title && <h1>{title}</h1>}
          <ul className="soundElementList">
            {equipmentList?.map((item, index) => (
              <li
                key={index}
                onMouseEnter={() =>
                  changeColorSVGPath(item.partHovered ?? undefined)
                }
                onMouseLeave={() => changeColorSVGPath("")}
                className={`${item.partHovered} ${className}`}
              >
                {item.nom}
              </li>
            ))}
          </ul>
        </div>
      )}
      {button}
      <div className={`imageWrapper ${className}`}>
        <div
          className="wrapperAigus"
          onMouseEnter={() => changeColorSVGPath("aigu")}
          onMouseLeave={() => changeColorSVGPath(undefined)}
        ></div>
        <div
          className="wrapperKicks"
          onMouseEnter={() => changeColorSVGPath("mid")}
          onMouseLeave={() => changeColorSVGPath(undefined)}
        ></div>
        <div
          className="wrapperSubs"
          onMouseEnter={() => changeColorSVGPath("sub")}
          onMouseLeave={() => changeColorSVGPath(undefined)}
        ></div>
        {svg}
      </div>
    </div>
  );
};

export default SoundElement;
