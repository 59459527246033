import "./CarrouselDesktop.scss";

import React, { useEffect, useRef } from "react";

import useAnimation from "../../../hooks/useAnimation";
import useWindowWidth from "../../../hooks/useWindowWidth";
import getImageUrl from "../../../Utils/Services/img/imgFetch";

interface CarrouselDesktopProps {
  imgArray: string[];
  className?: string;
  title: string;
}

const CarrouselDesktop: React.FC<CarrouselDesktopProps> = ({
  imgArray,
  className = "",
  title,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const { animateCarousel } = useAnimation();
  const { windowWidth } = useWindowWidth();

  useEffect(() => {
    const refreshScrollTrigger = () => {
      ScrollTrigger.refresh();
    };

    window.addEventListener("load", refreshScrollTrigger);

    // Nettoyage
    return () => {
      window.removeEventListener("load", refreshScrollTrigger);
    };
  }, [windowWidth]);

  animateCarousel(componentRef, sliderRef, titleRef, imgArray);

  return (
    <div
      ref={componentRef}
      className={`carrousel ${className}`}
      id="carrousel-section"
    >
      <h1 ref={titleRef}>{title}</h1>

      <div ref={sliderRef} className="frameContainer">
        {imgArray.map((img, index) => (
          <div className="carouselItem" key={img + index}>
            <img src={getImageUrl(img)} alt={`CarrouselDesktop ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarrouselDesktop;
